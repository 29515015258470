import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, Link, Paper, Theme } from '@material-ui/core';
import { Table } from './common/table';
import { format } from 'date-fns';
import { blue, green, red } from '@material-ui/core/colors';
import { ContractorLabel } from './ContractorLabel';
import { RegisterReceiptDialog } from 'src/pages/kkm/ui/RegisterReceiptForm';
import { BillingJournal as IBillingJournal } from 'src/shared/api';

export interface typeProps {
  journal?: IBillingJournal[] | null;
  totalCount?: number | null;
  loading?: boolean;
  withPagination?: boolean;
  limit?: number;
  offset?: number;
  cancel?: () => any;
  refetch?: () => any;
  onFetch?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournal' },
);

export const BillingJournal: React.FunctionComponent<typeProps> = ({
  journal,
  totalCount,
  limit = 0,
  offset = 0,
  loading,
  withPagination = false,
  cancel,
  refetch,
  onFetch,
}) => {
  const classes = useStyles({});
  

  const getJournalDirection = (journal: IBillingJournal) => {
    const cashbookPost = journal.posts?.find(
      post => post.account?.contractor === null,
    );
    if (!cashbookPost || cashbookPost?.value === 0) return 'default';
    if (cashbookPost?.value && cashbookPost?.value >= 0) return 'outgo';
    return 'income';
  };

  return (
    <div className={classes.root}>
      {journal?.map(item => (
        <Box key={item.id} mb={2}>
          <Paper style={{ display: 'flex', overflow: 'hidden' }}>
            <div
              style={{
                width: '6px',
                backgroundColor: {
                  default: 'unset',
                  income: green[500],
                  outgo: red[500],
                }[getJournalDirection(item)],
              }}
            ></div>
            <Box p={2} flexGrow={1}>
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  {item.id}{' '}
                  {item.createdAt &&
                    format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss')}
                    {' '}
                    {item?.user?.username}
                </Grid>
                <Grid item xs={2}>
                  {item.reason?.name}
                </Grid>
                <Grid item xs={2}>
                  {item.type?.name}
                </Grid>
                <Grid item xs={2}>
                  {item?.employee && (
                    <Link
                      href={`/employees/${item?.employee?.id}`}
                      target="_blank"
                    >
                      {item?.employee?.fullName}
                    </Link>
                  )}{' '}
                  {item?.order && (
                    <span>
                      Заказ{' '}
                      <Link href={`/orders/${item?.order?.id}`} target="_blank">
                        #{item?.order?.id}
                      </Link>
                    </span>
                  )}{' '}
                  {item?.comment && <span>{item?.comment}</span>}
                </Grid>
                <Grid item xs={12}>
                  {item.posts?.map((post, index) => (
                    <Box py="2px" key={post.id}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          {post.id}
                        </Grid>
                        <Grid item xs={2}>
                          {post?.account?.name}{' '}
                          <ContractorLabel
                            contractor={post?.account?.contractor}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            textAlign="right"
                            style={{
                              fontWeight: 500,
                              color:
                                post?.value && post?.value > 0
                                  ? green[500]
                                  : red[500],
                            }}
                          >
                            {(post?.value
                              ? post?.value / 100
                              : 0
                            ).toLocaleString('ru-RU')}{' '}
                            {post?.currency}
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          {item?.order &&
                            item?.order.contractor?.inn &&
                            post?.account?.contractor?.inn &&
                            post?.account?.contractor?.inn ===
                              item?.order.contractor?.inn && (
                              <RegisterReceiptDialog order={item?.order} />
                            )}
                        </Grid>
                      </Grid>
                      {item.posts && item.posts?.length > index + 1 && (
                        <Divider />
                      )}
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      ))}
    </div>
  );
};
