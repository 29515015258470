import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link } from '@material-ui/core';
import { useBillingAccounts } from 'src/api/billingApi';
import { Table } from 'src/components/common/table';
import { typeBillingAccount } from 'src/types';
import { BillingJournalCreate } from 'src/components/BillingJournalCreate';
import { ContractorLabel } from 'src/components/ContractorLabel';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      actions: {
        '& button': {
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'CourierAccountsPage' },
);

export const CourierAccountsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const [sumAccMap, setSumAccMap] = useState<{
    [key: number]: typeBillingAccount | null;
  }>({});

  const variables = { accounts: '35,36,64,50,51' };

  const { data, loading, refetch, cancel } = useBillingAccounts({
    variables,
  });

  return (
    <div className={classes.root}>
      {data?.data?.map(account => (
        <Box key={account.id} mb={2}>
          <Paper>
            <Box p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                fontSize="18px"
              >
                <Box>
                  <Link target="_blank" href={`/accounts/${account?.id}`}>
                    {account.name}
                  </Link>{' '}
                  <ContractorLabel contractor={account?.contractor} />
                </Box>
                <Box>
                  {![50, 51].includes(account?.id as number) &&
                    (account.balance
                      ? account.balance / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                  {account.currency}
                </Box>
              </Box>
              <Box display="flex" mt={2} justifyContent="end">
                <BillingJournalCreate
                  onCreate={() => refetch(variables)}
                  to={account}
                  withEmployee={false}
                  withCommission={false}
                />
                <Box ml={2}>
                  <BillingJournalCreate
                    add={false}
                    onCreate={() => refetch(variables)}
                    from={account}
                    withEmployee={false}
                    withCommission={false}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </div>
  );
};
