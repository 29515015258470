import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Button } from '@material-ui/core';
import { Autocomplete } from 'src/components/common/autocomplete';
import { useGetContractors, useGetJournalReasons } from 'src/api/billingApi';
import { typeBillingJournalReason } from 'src/types';
import { orderBy } from 'lodash';
import { Contractor } from 'src/shared/api';

type typeProps = {
  contractor?: Contractor | null;
  disabled?: boolean;
  label?: string;
  filter?: (item: typeBillingJournalReason) => boolean;
  onSelect?: (type: Contractor) => any;
  variant?: 'select' | 'buttons';
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ContractorSelect' },
);

export const ContractorSelect: React.FunctionComponent<typeProps> = ({
  filter: providedFilter,
  onSelect,
  disabled,
  label = 'Контрагент',
  contractor,
  variant = 'select',
  children,
}) => {
  const classes = useStyles({});
  const [value, setValue] = useState<Contractor | null>(null);

  const { data, loading } = useGetContractors();

  useEffect(() => {
    const valueFromArray =
      data && contractor
        ? (data?.data?.find(
            (item: any) => contractor && item?.id === contractor?.id,
          ) as Contractor)
        : null;
    if (valueFromArray) {
      setValue(valueFromArray);
    }
  }, [contractor, data]);

  const filter = (item: typeBillingJournalReason) => {
    if (providedFilter) return providedFilter(item);
    return item;
  };

  const contractors = data?.data?.filter(filter);

  const handleOnChange = (value: any) => {
    setValue(value);
    onSelect && onSelect(value);
  };

  if (variant === 'buttons') {
    return (
      <Box display="flex" overflow="auto">
        {children}
        {contractors
          ?.filter(contractor => contractor.alias !== 'tyurin')
          ?.map(item => (
            <Box key={item.id} p={1}>
              <Button
                onClick={() => handleOnChange(item)}
                variant={item.id === contractor?.id ? 'contained' : 'outlined'}
              >
                {item.name}
              </Button>
            </Box>
          ))}
      </Box>
    );
  }

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e, value: Contractor) => handleOnChange(value)}
        options={orderBy(data?.data?.filter(filter) || [], ['name'])}
        getOptionLabel={(option: Contractor) => `${option.name}`}
        label={label}
        loading={loading}
      />
    </Box>
  );
};
