import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Typography,
  ListItemIcon,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useBillingAccounts } from 'src/api/billingApi';
import { typeBillingAccount } from 'src/types';
import { green } from '@material-ui/core/colors';
import { ContractorSelect } from './ContractorSelect';
import { Contractor } from 'src/shared/api';
import { WithLoading } from './common/with-loading';
import { ContractorLabel } from './ContractorLabel';
import { ArrowForward, ArrowRight } from '@material-ui/icons';

type typeProps = {
  account?: typeBillingAccount | null;
  from?: typeBillingAccount | null;
  to?: typeBillingAccount | null;
  label?: string | null;
  title?: string | null;
  disabled?: boolean;
  contractor?: Contractor | null;
  onChange?: (account: typeBillingAccount) => any;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      cashBook: {
        fontWeight: 900,
        color: green[300],
        textTransform: 'uppercase',
      },
    }),
  { name: 'BillingAccountSelect' },
);

export const BillingAccountSelect: React.FunctionComponent<typeProps> = ({
  account,
  onChange,
  disabled,
  contractor: providedContractor,
  from,
  to,
  label = 'Счет',
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [contractor, setContractor] = useState<null | Contractor>(null);
  const [value, setValue] = useState<typeBillingAccount | null>(null);

  const handleClose = () => {
    setEmpty(false);
    setOpen(false);
    setContractor(null);
    setValue(null);
  };

  useEffect(() => {
    if (providedContractor) setContractor(providedContractor);
  }, [providedContractor]);

  const { data, loading } = useBillingAccounts({
    variables: { contractors: contractor?.id },
    skip: !contractor?.id,
  });

  useEffect(() => {
    if (data?.data?.[0] && !Number.isInteger(data?.data?.[0].balance))
      setEmpty(true);
  }, [data?.data]);

  const accounts = data?.data
    ?.filter(account => {
      if (account?.isClosed) return false;
      return empty ? true : account.balance;
    })
    ?.sort((a: typeBillingAccount, b: typeBillingAccount) =>
      a.name && b.name && a.name > b.name ? 1 : -1,
    );

  useEffect(() => {
    if (account) {
      setValue(account);
    }
  }, [account]);

  const handleOnChange = (value: any) => {
    setValue(value);
    onChange && onChange(value);
    setOpen(false);
  };

  const accountsByContractors = useMemo(
    () =>
      data?.data?.reduce(
        (prev, curr) =>
          //@ts-ignore
          prev[curr?.contractor?.alias]
            ? {
                ...prev,
                //@ts-ignore
                [curr?.contractor?.alias]: [
                  //@ts-ignore
                  ...prev[curr?.contractor?.alias],
                  curr,
                ],
              }
            : //@ts-ignore
              { ...prev, [curr?.contractor?.alias]: [curr] },
        {},
      ),
    [data],
  ) as any;

  return (
    <Box className={classes.root} display="flex">
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        fullWidth
        size="large"
        variant={value?.id === 1 ? 'contained' : 'outlined'}
        color={value?.id === 1 ? 'primary' : undefined}
      >
        {value ? (
          <Box style={{ textTransform: 'none' }}>
            {value?.name}{' '}
            {value?.contractor && (
              <ContractorLabel contractor={value?.contractor} />
            )}{' '}
            {value?.currency &&
              `(
            ${value?.currency})`}
          </Box>
        ) : (
          label
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <ContractorSelect
            variant="buttons"
            contractor={contractor}
            onSelect={setContractor}
          >
            <Box p={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOnChange({ id: 1, name: 'CASH BOOK' })}
              >
                CASHBOOK
              </Button>
            </Box>
          </ContractorSelect>
        </DialogTitle>
        <DialogContent>
          <Box p={2} display="flex" justifyContent="space-between">
            <FormControlLabel
              control={<Checkbox checked={empty} />}
              onChange={() => setEmpty(!empty)}
              label="Пустые счета"
            />
          </Box>
          <WithLoading loading={loading}>
            <Box minHeight="48px" width="100%">
              <List>
                {!loading &&
                  accounts?.map(item => (
                    <ListItem
                      key={item.id}
                      dense
                      divider
                      button
                      onClick={() => handleOnChange(item)}
                    >
                      <ListItemIcon>
                        <Radio checked={item.id === account?.id} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span>
                            {item.name}{' '}
                            <ContractorLabel contractor={item.contractor} /> (
                            {item.currency})
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        {item.balance &&
                          (item.balance / 100).toLocaleString('ru-RU')}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </WithLoading>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
