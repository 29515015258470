import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Box,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useGetBillingJournal } from 'src/api/billingApi';
import { Table } from 'src/components/common/table';
import { typeBillingJournal } from 'src/types';
import { BillingJournalCreate } from 'src/components/BillingJournalCreate';
import { BillingJournal } from 'src/components/BillingJournal';
import { useRouter } from 'src/hooks/useRouter';
import { format, startOfDay, endOfDay } from 'date-fns';
import { green, red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ru from 'date-fns/locale/ru';
import { LoansJournals } from 'src/components/LoansJournals';
import { BillingJournal as IBillingJournal } from 'src/shared/api';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'BillingJournalPage' },
);

export const BillingJournalPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const [tab, setTab] = useState(0);

  const { query, push } = useRouter();

  const variables = {
    from: query.from || format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    to: query.to || format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    reasons: query?.reasons
  };

  const { data, loading, refetch, cancel } = useGetBillingJournal({
    variables,
  });

  const handleRefetch = (params: any = {}) => {
    push(null, { ...variables, ...params });
  };

  const reasons = Object.values(
    data?.data?.reduce((prev: any, curr: IBillingJournal) => {
      if (!curr.reason?.id) return prev;
      const result = { ...prev };
      if (result[curr.reason?.id]) {
        result[curr.reason?.id] = {
          ...curr.reason,
          journal: [...prev[curr.reason?.id].journal, curr],
        };
      }
      if (!result[curr.reason?.id]) {
        result[curr.reason?.id] = { ...curr.reason, journal: [curr] };
      }
      return result;
    }, {}) || {},
  );

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Tabs onChange={(e, value) => setTab(value)} value={tab}>
          <Tab label="Операции" value={0} />
          <Tab label="Займы" value={1} />
        </Tabs>
      </Box>
      {tab === 0 && (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <DatePicker
                  cancelLabel="Отмена"
                  okLabel={null}
                  autoOk
                  label="От"
                  format="yyyy-MM-dd"
                  fullWidth
                  inputVariant="outlined"
                  value={new Date(variables.from)}
                  onChange={date =>
                    handleRefetch({
                      // @ts-ignore
                      from: format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DatePicker
                  cancelLabel="Отмена"
                  okLabel={null}
                  autoOk
                  format="yyyy-MM-dd"
                  fullWidth
                  label="До"
                  inputVariant="outlined"
                  value={new Date(variables.to)}
                  onChange={date =>
                    handleRefetch({
                      // @ts-ignore
                      to: format(endOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                    })
                  }
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Box mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Основания движения денег</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%">
                  <Table
                    title=" "
                    data={reasons || []}
                    totalCount={data?.totalCount}
                    headers={[
                      {
                        title: 'Название',
                        extract: (item: any) => item.name,
                      },
                      {
                        title: 'Кол-во операций',
                        extract: (item: any) => item.journal.length,
                      },
                      {
                        title: 'Сумма',
                        align: 'right',
                        extract: (item: any) => (
                          <div>
                            {(
                              item.journal.reduce(
                                (prev: number, curr: typeBillingJournal) =>
                                  curr?.posts &&
                                  curr?.posts
                                    ?.filter(post => post?.account?.ownerName)
                                    .reduce(
                                      (prev, curr) =>
                                        (curr.value && prev + curr.value) || 0,
                                      0,
                                    ) + prev,
                                0,
                              ) / 100
                            ).toLocaleString('ru-RU')}
                          </div>
                        ),
                      },
                      {
                        title: 'Изменения',
                        align: 'right',
                        extract: (item: any) => (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                          >
                            <Box fontSize="12px" color={green[400]}>
                              +{' '}
                              {(
                                item.journal.reduce(
                                  (prev: number, curr: typeBillingJournal) =>
                                    curr?.posts &&
                                    curr?.posts
                                      ?.filter(
                                        post =>
                                          post?.account?.ownerName &&
                                          post.value &&
                                          post.value > 0,
                                      )
                                      .reduce(
                                        (prev, curr) =>
                                          (curr.value && prev + curr.value) ||
                                          0,
                                        0,
                                      ) + prev,
                                  0,
                                ) / 100
                              ).toLocaleString('ru-RU')}
                            </Box>

                            <Box fontSize="12px" color={red[400]}>
                              {(
                                item.journal.reduce(
                                  (prev: number, curr: typeBillingJournal) =>
                                    curr?.posts &&
                                    curr?.posts
                                      ?.filter(
                                        post =>
                                          post?.account?.ownerName &&
                                          post.value &&
                                          post.value < 0,
                                      )
                                      .reduce(
                                        (prev, curr) =>
                                          (curr.value && prev + curr.value) ||
                                          0,
                                        0,
                                      ) + prev,
                                  0,
                                ) / 100
                              ).toLocaleString('ru-RU')}
                            </Box>
                          </Box>
                        ),
                      },
                    ]}
                    loading={loading}
                    onCancel={cancel}
                    withPagination={false}
                    onFetch={refetch}
                    limit={0}
                    offset={0}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <BillingJournalCreate onCreate={() => handleRefetch({})} />
          </Box>
          <Box mt={2}>
            <BillingJournal
              journal={data?.data}
              totalCount={data?.totalCount}
              loading={loading}
              refetch={handleRefetch}
              onFetch={handleRefetch}
              cancel={cancel}
            />
          </Box>
        </>
      )}
      {tab === 1 && <LoansJournals />}
    </div>
  );
};
